import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { useSnackbar } from 'notistack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// components
import {
  Box,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  CardHeader,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import 'moment/locale/es';
import Scrollbar from '../../components/Scrollbar';
import ToolbarComponent from '../../components/ui/Toolbar/ToolbarComponent2';
import { getCompaniesByUser } from '../../services/CompanyService';
import { getNodoByCompany, getNodoById, softDeleteNodo } from '../../_apis_/nodo';
import InvitationTable from '../../components/ui/Table/InvitationTable';
import InvitationTable2 from '../../components/ui/Table/InvitationTable2';
import { deleteUserInCompany, editStatusFromUser, listenForChangesnotifications } from '../../services/UserService';
import { getProfileSuccess } from '../../redux/slices/user';
import {
  getalldeviceById,
  MultiApiDataUpdatemeasuresById,
  getDevicesByCompany,
  getDevicesMeasuremntesByDate,
  getDevicesByCompanysql,
  getDevicesMeasuremntesByDatesqlvistas,
  getDevicesMeasuremntesByDatevistas,
  MultiApiDataUpdateDeviceById,
} from '../../_apis_/multiapiData';

import { getCompanyById } from '../../_apis_/companies';

const TABLE_HEAD_INVITE = [
  { id: 'companyName', label: 'Nombre de la organización', alignRight: false },
  { id: '', label: 'Acciones', alignRight: false },
];

const OtherOrganizations = ({
  pendingCompanies,
  setPendingCompanies,
  title,
  TABLE_HEAD,
  getDocuments,
  nameInSpanish,
  nameInEnglish,
  urlToDownloadSheet,
  isMasculine,
  needMassiveCharge,
  documents,

  isList,
  handleClickItem,
  tableStyle,
  dontNeedCheckBox,
  dontNeedCreateButton,
  anotherButton,
  anotherTextButton,
}) => {
  const { myProfile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [otherCompanies, setOtherCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [roleChange, setRoleChange] = useState(true);
  const [page, setPage] = React.useState(0); // Página actual
  // Número de filas por página
  const [devices, setDevices] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [devicesWithAlarm1, setDevicesWithAlarm1] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedTab, setSelectedTab] = useState();
  const [pendingCompaniesCount, setPendingCompaniesCount] = useState(0);
  const [alarmsCount, setAlarmsCount] = useState(0);
  const [selectedId, setSelectedId] = useState('');
  const [devices2, setDevices2] = useState([]);
  const [devices3, setDevices3] = useState([]);
  const [devices4, setDevices4] = useState([]);
  const [devices5, setDevices5] = useState([]);
  const { currentBranch, currentCompany } = useSelector((state) => state.company);
  const [tabValue, setTabValue] = useState(0);

  const filterCompanyInList = (companyList, setCompanyList, company) => {
    const newCompanies = companyList.filter((el) => el.id !== company.id);
    setCompanyList(newCompanies);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function reemplazarNombreVariable(variable) {
    const nuevoNombre = variable.replace(/^site(\d+)$/i, 'data$1');
    return nuevoNombre;
  }

  const getLatestDeviceMeasurement2 = useCallback(
    async (device, clientType, measurementDefinitionId) => {
      const resultNode = await getNodoById(device.idNodo, currentCompany.id);
      const lastMeasurementDate = new Date();
      lastMeasurementDate.setDate(lastMeasurementDate.getDate() - 7);
      const endDate = new Date();
      let devicesList = [];

      try {
        if (resultNode.type === 'KOLIBRI') {
          devicesList = await getDevicesMeasuremntesByDatevistas(
            clientType,
            device.idNodo,
            device.deviceId,
            measurementDefinitionId,
            lastMeasurementDate,
            endDate
          );
        } else if (resultNode.type === 'SQL') {
          const deviceId = await reemplazarNombreVariable(device.Site);
          devicesList = await getDevicesMeasuremntesByDatesqlvistas(
            clientType,
            device.idNodo,
            deviceId,
            measurementDefinitionId,
            lastMeasurementDate,
            endDate
          );
        }

        if (devicesList.length > 0) {
          return devicesList[devicesList.length - 1].value;
        }

        return null;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [currentCompany.id]
  );

  const formatMeasurement = (measurement, unit, multiplier) => {
    if (measurement === null || measurement === undefined) {
      return null; // Retorna null si no hay medición.
    }

    let value = measurement;

    // Aplica el multiplicador si está disponible
    if (multiplier) {
      value *= multiplier;
    }

    // Formatea el valor con dos decimales
    const formattedValue = value.toFixed(2);

    // Agrega la unidad si está disponible
    return unit ? `${formattedValue} ${unit}` : formattedValue;
  };

  const getUnitAndMultiById = (measurementDefinitions, measurementDefinitionId) => {
    const definition = measurementDefinitions.find((def) => def.id === measurementDefinitionId);

    if (!definition) {
      return { unidad: '', multi: 1 };
    }

    return {
      unidad: definition.unit || '', // Devuelve la unidad o una cadena vacía si no está definida
      multi: definition.multiplier || 1, // Devuelve el multiplicador o 1 si no está definido
    };
  };

  useEffect(() => {
    const getInformation = async () => {
      setIsLoading(true);
      try {
        const company = await getCompanyById(currentCompany.id);

        // Obtener dispositivos simultáneamente
        const [data, data2] = await Promise.all([getDevicesByCompany(company), getDevicesByCompanysql(company)]);

        // Filtrar y mapear datos combinados
        const combinedData = data.concat(data2).filter((device) => device.status === 'activo');
        const resulDevice = combinedData.map((el) => ({
          ...el,
          organizations: (el.companies || []).map((org) => org.companyName).join(', '),
          zones: (el.zones || []).map((zone) => zone.nombre).join(', '),
        }));

        // Función para obtener mediciones agrupadas
        const getGroupedMeasurements = async (el, type) => {
          const params =
            type === 'KOLIBRI'
              ? [el.temperatura, el.tempbaro, el.presion, el.nivel]
              : [el.temperatura, el.tempbaro, el.presion, el.nivel, el.caudal, el.fuga];

          return getLatestDeviceMeasurement2(el, type, params);
        };

        // Función auxiliar para formatear mediciones de manera segura
        const safeFormatMeasurement = (measurement, unit, multi) => {
          if (measurement == null) return null;
          return formatMeasurement(measurement, unit, multi);
        };

        // Crear promesas para obtener mediciones
        const promises = resulDevice.map(async (el) => {
          const [measurementsKOLIBRI, measurementsSQL] = await Promise.all([
            getGroupedMeasurements(el, 'KOLIBRI'),
            getGroupedMeasurements(el, 'SQL'),
          ]);

          const measurementDefinitions = el.measurementDefinitions || [];
          const getUnitAndMulti = (id) => {
            const def = measurementDefinitions.find((d) => d.id === id) || {};
            return { unidad: def.unidad, multi: def.multi };
          };

          const formattedData = {
            tempe: safeFormatMeasurement(
              measurementsKOLIBRI?.[el.temperatura],
              ...Object.values(getUnitAndMulti(el.temperatura))
            ),
            temp: safeFormatMeasurement(
              measurementsKOLIBRI?.[el.tempbaro] || measurementsSQL?.[el.tempbaro],
              ...Object.values(getUnitAndMulti(el.tempbaro))
            ),
            pre: safeFormatMeasurement(
              measurementsKOLIBRI?.[el.presion] || measurementsSQL?.[el.presion],
              ...Object.values(getUnitAndMulti(el.presion))
            ),
            niv: safeFormatMeasurement(measurementsKOLIBRI?.[el.nivel], ...Object.values(getUnitAndMulti(el.nivel))),
            cau: safeFormatMeasurement(measurementsSQL?.[el.caudal], ...Object.values(getUnitAndMulti(el.caudal))),
            fu: safeFormatMeasurement(measurementsSQL?.[el.fuga], null, getUnitAndMulti(el.fuga).multi),
          };

          const filteredData = Object.fromEntries(
            Object.entries(formattedData).filter(
              ([_, value]) => value != null && value.trim() !== '0' && parseFloat(value) !== 0
            )
          );

          return { ...el, ...filteredData };
        });

        const newDevices = await Promise.all(promises);
        setDevices(newDevices);
      } catch (error) {
        console.error('Error fetching information:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getInformation();
  }, [currentCompany.id, getLatestDeviceMeasurement2]);

  const filteredDevices = useMemo(() => {
    return devices.filter((device) => {
      const tieneAlarma = false; // Lógica para verificar si hay alarmas
      const tieneMediciones = Object.values(device).some((val) => val !== null && val !== 0);
      return tieneAlarma || tieneMediciones;
    });
  }, [devices]);

  const voltageAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      // Convertir voltaje a número para comparación
      const voltage = Number(device.batteryInfoVoltageInVolt);

      if ((device.marca === 'Keller' && voltage < 3.4) || (device.marca === 'HWM' && voltage < 6)) {
        // Alarma para Keller si el voltaje es menor a 3.4
        // Alarma para HWM si el voltaje es menor a 6
        device.voltageAlarm = true;
        return true;
      }

      device.voltageAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma por voltaje
  const voltageAlarmCount = voltageAlarmDevices.length;

  // Puedes usar voltageAlarmCount como desees en tu aplicación.

  // Puedes usar voltageAlarmCount como desees en tu aplicación.

  // Puedes usar voltageAlarmCount como desees en tu aplicación.

  // Filtrar dispositivos y verificar la condición de alarma de batería
  const batteryAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo' && device.marca === 'Keller')
    .filter((device) => {
      // Convertir el porcentaje de capacidad a número para comparación
      const batteryCapacityPercent = Number(device.batteryInfoCapacityInPercent);

      if (batteryCapacityPercent < 70) {
        // Alarma si el porcentaje de capacidad es menor a 70
        device.batteryAlarm = true;
        return true;
      }

      device.batteryAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma de batería
  const batteryAlarmCount = batteryAlarmDevices.length;

  // Puedes usar batteryAlarmCount como desees en tu aplicación.
  // Filtrar dispositivos y verificar la condición de alarma por señal
  const signalAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      // Convertir la señal a número para comparación
      const signalQuality = Number(device.signalQuality);

      if (signalQuality < 10) {
        // Alarma si la señal es menor a 10
        device.signalAlarm = true;
        return true;
      }

      device.signalAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma por señal
  const signalAlarmCount = signalAlarmDevices.length;

  // Puedes usar signalAlarmCount como desees en tu aplicación.

  const comunicacionValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => Number(row.comunicacionValue));

  const humidityValues = filteredDevices
    .filter((device) => device.status === 'activo' && device.marca === 'Keller')
    .map((row) => {
      const humidityValue = Number(row.humidity);

      // Añadir lógica de alarma para la humedad
      if (humidityValue > 90) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        row.humidityAlarm = true; // Añadir una propiedad humidityAlarm para contar después.
      } else {
        row.humidityAlarm = false;
      }

      return humidityValue;
    });
  const temperatureAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      const lastTransmissionDateTime = new Date(device.lastMeasurementTransmissionDateTimeUtc);

      // Calcular la diferencia de tiempo entre la última transmisión y la hora actual

      const temperaturaNumerica = parseFloat(device.tempe);
      // Verificar si la diferencia de tiempo supera el límite establecido
      if (device.marca === 'HWM' && temperaturaNumerica > 35.0) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        device.temperaturenAlarm = true; // Añadir una propiedad communicationAlarm para contar después.
        return true; // Este dispositivo cumple con la condición de alarma.
        // eslint-disable-next-line no-else-return
      } else {
        device.temperatureAlarm = false;
        return false; // Este dispositivo no cumple con la condición de alarma.
      }
    });

  const humidityAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo' && device.marca === 'Keller')
    .filter((device) => {
      // Convertir la humedad a número para comparación
      const humidityValue = Number(device.humidity);

      if (humidityValue > 90) {
        // Alarma si la humedad es mayor a 90
        device.humidityAlarm = true; // Añadir una propiedad humidityAlarm para contar después.
        return true;
      }

      device.humidityAlarm = false;
      return false;
    });

  // Contar dispositivos con alarma de humedad
  const humidityAlarmDeviceNames = humidityAlarmDevices.map((device) => device.name);
  const humidityAlarmDeviceNamesti = humidityAlarmDevices.map(
    (device) => device.lastMeasurementTransmissionDateTimeUtc
  );
  // Contar dispositivos con alarma de humedad
  const humidityAlarmDeviceNames1 = humidityAlarmDevices.map((device) => device);
  const temperatureAlarmDeviceNames1 = temperatureAlarmDevices.map((device) => device);
  const humidityAlarmCount = humidityAlarmDevices.length;

  const sensorValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => Number(row.sensorValue));

  const temperaturaValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => Number(row.temperaturaValue));

  const señalValues = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => {
      const signalValue = Number(row.signalQuality);

      // Añadir lógica de alarma
      if (signalValue < 10) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        row.hasAlarm = true; // Añadir una propiedad hasAlarm para contar después.
      } else {
        row.hasAlarm = false;
      }

      return signalValue;
    });

  // Contar dispositivos con alarma
  const devicesWithAlarm = señalValues.filter((value) => value < 10);
  const alarmCount = devicesWithAlarm.length;

  const lastMeasurementTransmissionDateTimeUtc = filteredDevices
    .filter((device) => device.status === 'activo')
    .map((row) => row.lastMeasurementTransmissionDateTimeUtc);

  const currentTime = new Date();

  // Definir el límite de tiempo para la alarma (24 horas en milisegundos)
  const alarmTimeLimit = 24 * 60 * 60 * 1000; // 24 horas en milisegundos

  // Filtrar dispositivos y verificar la condición de alarma de comunicación
  const communicationAlarmDevices = filteredDevices
    .filter((device) => device.status === 'activo')
    .filter((device) => {
      const lastTransmissionDateTime = new Date(device.lastMeasurementTransmissionDateTimeUtc);

      // Calcular la diferencia de tiempo entre la última transmisión y la hora actual
      const timeDifference = currentTime - lastTransmissionDateTime;

      // Verificar si la diferencia de tiempo supera el límite establecido
      if (timeDifference > alarmTimeLimit) {
        // Puedes hacer algo aquí para indicar la alarma, por ejemplo, cambiar el color
        // de la barra en el gráfico.
        device.communicationAlarm = true; // Añadir una propiedad communicationAlarm para contar después.
        return true; // Este dispositivo cumple con la condición de alarma.
        // eslint-disable-next-line no-else-return
      } else {
        device.communicationAlarm = false;
        return false; // Este dispositivo no cumple con la condición de alarma.
      }
    });

  // Contar dispositivos con alarma de comunicación
  const communicationAlarmCount = communicationAlarmDevices.length;
  const voltageAlarmDeviceNames = voltageAlarmDevices.map((device) => device.name);
  const voltageAlarmDeviceNamesti = voltageAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
  const voltageAlarmDeviceNames1 = voltageAlarmDevices.map((device) => device);
  // Obtener nombres de dispositivos con alarma de batería
  const batteryAlarmDeviceNames = batteryAlarmDevices.map((device) => device.name);
  const batteryAlarmDeviceNamesti = batteryAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
  const batteryAlarmDeviceNames1 = batteryAlarmDevices.map((device) => device);
  const signalAlarmDeviceNames = signalAlarmDevices.map((device) => device.name);
  const signalAlarmDeviceNamesti = signalAlarmDevices.map((device) => device.lastMeasurementTransmissionDateTimeUtc);
  // Obtener nombres de dispositivos con alarma de comunicación
  const signalAlarmDeviceNames1 = signalAlarmDevices.map((device) => device);

  const communicationAlarmDeviceNames = communicationAlarmDevices.map((device) => device.name);
  const communicationAlarmDeviceNamesti = communicationAlarmDevices.map(
    (device) => device.lastMeasurementTransmissionDateTimeUtc
  );

  const communicationAlarmDeviceNames1 = communicationAlarmDevices.map((device) => device);

  const temperatureAlarmDeviceNames = temperatureAlarmDevices.map((device) => device.name);

  const combinedArray = communicationAlarmDeviceNames1.concat(
    signalAlarmDeviceNames1,
    batteryAlarmDeviceNames1,
    voltageAlarmDeviceNames1,
    humidityAlarmDeviceNames1,
    temperatureAlarmDeviceNames1
  );

  // Alternativamente, puedes utilizar el operador spread (...) para lograr lo mismo
  // const combinedArray = [...communicationAlarmDeviceNames1, ...signalAlarmDeviceNames1, ...batteryAlarmDeviceNames1, ...voltageAlarmDeviceNames1, ...humidityAlarmDeviceNames1];

  combinedArray.sort((a, b) => {
    const dateA = moment(a.lastMeasurementTransmissionDateTimeUtc);
    const dateB = moment(b.lastMeasurementTransmissionDateTimeUtc);
    return dateB - dateA;
  });

  useEffect(() => {
    // Actualiza el estado con el array combinado
    setDevicesWithAlarm1(combinedArray);
  }, []);

  const displayedDevices = new Set();

  const getFormattedDate = (dateString) => {
    const date = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSSSSS');
    date.locale('es');
    return date.format('dddd DD [de] MMMM [a las] h:mm A YYYY');
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAccept = async (company) => {
    const newCompany = {
      ...company,
      name: company.companyName,
    };
    const companyId = company.id;
    const { roleId } = myProfile.companies[companyId];

    filterCompanyInList(pendingCompanies, setPendingCompanies, company);

    const newList = otherCompanies;
    newList.push(company);
    setOtherCompanies(newList);

    const newPendingCompanies = pendingCompanies.filter((el) => el.id !== company.id);
    setPendingCompanies(newPendingCompanies);

    // cambiar myprofile por newProfile
    const newUser = await editStatusFromUser(myProfile, roleId, newCompany, 'aceptado');
    dispatch(getProfileSuccess(newUser));

    enqueueSnackbar(`Invitación a ${company.companyName} aceptada`, {
      variant: 'success',
    });

    // Reload the page to reflect the changes
    window.location.reload();
  };

  const handlesearch = (pathId) => {
    console.log('pathId', pathId);

    // Codifica el pathId para preservar los espacios y otros caracteres especiales
    const encodedPathId = encodeURIComponent(pathId);

    // Redirige a la URL con el pathId codificado
    window.location.href = `/dashboard/redireccionalarm/${encodedPathId}`;
  };

  const handleDecline = async (company) => {
    const newCompany = {
      ...company,
      name: company.companyName,
    };
    const companyId = company.id;
    const { roleId } = myProfile.companies[companyId];

    filterCompanyInList(pendingCompanies, setPendingCompanies, company);
    const newPendingCompanies = pendingCompanies.filter((el) => el.id !== company.id);
    setPendingCompanies(newPendingCompanies);
    const newUser = await editStatusFromUser(myProfile, roleId, newCompany, 'rechazado');
    dispatch(getProfileSuccess(newUser));

    const name = `${myProfile.firstName} ${myProfile.lastName}`;
    await deleteUserInCompany(companyId, myProfile.uid, true, name, 'reject');

    enqueueSnackbar(`Invitación a ${company.companyName} rechazada`, {
      variant: 'success',
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getPendingCompanies = useCallback(async () => {
    try {
      const companies = await getCompaniesByUser(myProfile, ['pendiente']);
      setPendingCompanies(companies);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleChange]);

  useEffect(() => {
    // Calcula la cantidad de invitaciones pendientes
    setPendingCompaniesCount(pendingCompanies.length);

    const filterDevicesWithValidacion = (devices) => devices.filter((device) => device.validacion2 === false);
    // Calcula la cantidad de alarmas únicas
    const filteredCommunicationAlarmDevices = filterDevicesWithValidacion(communicationAlarmDeviceNames1);
    const filteredSignalAlarmDevices = filterDevicesWithValidacion(signalAlarmDeviceNames1);
    const filteredBatteryAlarmDevices = filterDevicesWithValidacion(batteryAlarmDeviceNames1);
    const filteredVoltageAlarmDevices = filterDevicesWithValidacion(voltageAlarmDeviceNames1);
    const filteredHumidityAlarmDevices = filterDevicesWithValidacion(humidityAlarmDeviceNames1);
    const filteredtemperatureAlarmDevices = filterDevicesWithValidacion(temperatureAlarmDeviceNames1);

    const uniqueDevices = new Set([
      ...filteredCommunicationAlarmDevices,
      ...filteredSignalAlarmDevices,
      ...filteredBatteryAlarmDevices,
      ...filteredVoltageAlarmDevices,
      ...filteredHumidityAlarmDevices,
      ...filteredtemperatureAlarmDevices,
    ]);

    setAlarmsCount(uniqueDevices.size);
  }, [
    pendingCompanies,
    communicationAlarmDeviceNames1,
    signalAlarmDeviceNames1,
    batteryAlarmDeviceNames1,
    voltageAlarmDeviceNames1,
    humidityAlarmDeviceNames1,
    temperatureAlarmDeviceNames1,
  ]);

  // const getOtherCompanies = useCallback(async () => {
  //   try {
  //     const companies = await getCompaniesByUser(myProfile, 'aceptado');
  //     setOtherCompanies(companies);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.error(err);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label={`Invitaciones (${pendingCompaniesCount})`} />
        <Tab label={`Alarmas (${alarmsCount})`} />
        {/* Agregar más Tabs si es necesario */}
      </Tabs>

      {selectedTab === 0 && (
        <InvitationTable
          title="Invitaciones a organizaciones"
          TABLE_HEAD={TABLE_HEAD_INVITE}
          getDocuments={getPendingCompanies}
          nameInSpanish="Organización"
          nameInEnglish="organization"
          isMasculine={Boolean(false)}
          needMassiveCharge={Boolean(false)}
          isLoading={isLoading}
          documents={pendingCompanies}
          isList="branches"
          // handleClickItem={handleSelectedCompany}
          tableStyle={{ marginTop: '-10px' }}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
        />
      )}

      {title && (
        <CardHeader
          title={
            <Grid>
              <Grid container justifyContent="center" marginTop="-100px">
                <Typography variant="h6" paddingTop={20}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
      <ToolbarComponent
        numSelected={selected.length}
        id={selected.length === 1 ? selectedId : null}
        setSelected={setSelected}
        name={nameInSpanish}
        isMasculine={isMasculine}
        nameInEnglish={nameInEnglish}
        needMassiveCharge={needMassiveCharge}
        urlToDownloadSheet={urlToDownloadSheet}
        dontNeedCreateButton={dontNeedCreateButton}
        anotherButton={anotherButton}
        anotherTextButton={anotherTextButton}
      />

      {selectedTab === 1 && (
        <TableContainer
          component={Paper}
          style={{ maxHeight: '300px', overflow: 'auto', marginTop: '-10px', marginLeft: '10px' }}
        >
          <Table>
            <TableBody>
              {combinedArray.map((row) => {
                const { id, name, lastMeasurementTransmissionDateTimeUtc, validacion2 } = row;
                const isItemSelected = selected.indexOf(id) !== -1;

                if (name && lastMeasurementTransmissionDateTimeUtc && validacion2 === false) {
                  // Add condition for validacion
                  const alarmsToShow = [];

                  // Verificar si el dispositivo ya se ha mostrado
                  if (!displayedDevices.has(name)) {
                    if (batteryAlarmDeviceNames.includes(row.name)) {
                      alarmsToShow.push({
                        type: 'Batería',
                        dateString: getFormattedDate(row.lastMeasurementTransmissionDateTimeUtc),
                      });
                    }

                    if (voltageAlarmDeviceNames.includes(row.name)) {
                      alarmsToShow.push({
                        type: 'Voltaje',
                        dateString: getFormattedDate(row.lastMeasurementTransmissionDateTimeUtc),
                      });
                    }

                    if (signalAlarmDeviceNames.includes(row.name)) {
                      alarmsToShow.push({
                        type: 'Señal',
                        dateString: getFormattedDate(row.lastMeasurementTransmissionDateTimeUtc),
                      });
                    }

                    if (humidityAlarmDeviceNames.includes(row.name)) {
                      alarmsToShow.push({
                        type: 'Humedad',
                        dateString: getFormattedDate(row.lastMeasurementTransmissionDateTimeUtc),
                      });
                    }

                    if (communicationAlarmDeviceNames.includes(row.name)) {
                      alarmsToShow.push({
                        type: 'Comunicación',
                        dateString: getFormattedDate(row.lastMeasurementTransmissionDateTimeUtc),
                      });
                    }

                    if (temperatureAlarmDeviceNames.includes(row.name)) {
                      alarmsToShow.push({
                        type: 'Temperatura',
                        dateString: getFormattedDate(row.lastMeasurementTransmissionDateTimeUtc),
                      });
                    }

                    // Repite lo mismo para otros tipos de alarmas

                    // Mostrar la información en la tabla
                    if (alarmsToShow.length > 0) {
                      displayedDevices.add(name); // Agregar el dispositivo al conjunto de dispositivos mostrados

                      return (
                        <TableRow style={{ marginBottom: '-28px' }} key={id}>
                          <TableCell style={{ width: '100%', textAlign: 'center' }}>
                            <div
                              role="button"
                              style={{
                                margin: '10px',
                                padding: '10px',
                                border: '1px solid #000',
                                borderRadius: '10px',
                                cursor: 'pointer',
                              }}
                              onClick={() => handlesearch(row.name)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handlesearch(row.name);
                                }
                              }}
                              tabIndex={0}
                            >
                              {alarmsToShow.map((alarm, index) => (
                                <div key={index}>
                                  {`Alarma de ${alarm.type} (${row.name})`}
                                  <div>
                                    <AccessTimeIcon fontSize="12" style={{ marginRight: '4px', marginTop: '13px' }} />
                                    <span>Fecha:</span>
                                    {alarm.dateString}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }
                }

                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

OtherOrganizations.propTypes = {
  pendingCompanies: PropTypes.array,
  setPendingCompanies: PropTypes.any,
};

export default OtherOrganizations;
