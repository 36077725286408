import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
// import { getNodoById } from './companies';
import { createLog } from '../redux/slices/log';

export const createDevicesSincrochanel = async (companyid, deviceId, data) => {
  try {
    const devicesRef = firebase.database().ref(`/channel/${companyid}/devices`);
    const deviceSnapshot = await devicesRef.child(deviceId).once('value');

    if (deviceSnapshot.exists()) {
      const existingData = deviceSnapshot.val();

      // Combina todas las propiedades, excepto measurementDefinitions
      const mergedData = {
        ...existingData,
        ...data,
        measurementDefinitions: existingData.measurementDefinitions, // Mantén las definiciones existentes
      };

      await devicesRef.child(deviceId).update(mergedData);
      console.log('Data Updated Successfully!');
    } else {
      console.log('Data does not exist. Creating new entry.');

      await devicesRef.child(deviceId).set(data);
      console.log('New Data Set Successfully!');
    }
  } catch (error) {
    console.error('Error in createDevicesSincro:', error);
    throw error;
  }
};

export const createDevicesSincro = async (client, deviceId, data) => {
  try {
    const devicesRef = firebase.database().ref(`/multiApiData/${client.type}/${client.id}/devices`);
    const deviceSnapshot = await devicesRef.child(deviceId).once('value');

    if (deviceSnapshot.exists()) {
      const existingData = deviceSnapshot.val();

      // Combina todas las propiedades, excepto measurementDefinitions
      const mergedData = {
        ...existingData,
        ...data,
        measurementDefinitions: existingData.measurementDefinitions, // Mantén las definiciones existentes
      };

      await devicesRef.child(deviceId).update(mergedData);
      console.log('Data Updated Successfully!');
    } else {
      console.log('Data does not exist. Creating new entry.');

      await devicesRef.child(deviceId).set(data);
      console.log('New Data Set Successfully!');
    }
  } catch (error) {
    console.error('Error in createDevicesSincro:', error);
    throw error;
  }
};

export const getDevicesByCompany = async (company) => {
  const devicesList = [];
  const nodeIds = company.nodes || [];

  if (nodeIds.length === 0) return devicesList;

  // Crear una única consulta para todos los nodeIds
  const promises = nodeIds.map((nodeId) =>
    firebase.database().ref(`/multiApiData/KOLIBRI/${nodeId}/devices`).once('value')
  );

  const snapshots = await Promise.all(promises);

  snapshots.forEach((snapshot) => {
    const devices = snapshot.val();
    if (devices) {
      Object.keys(devices).forEach((key) => {
        devicesList.push(devices[key]);
      });
    }
  });

  return devicesList;
};

export const getDevicesByCompanysql = async (company) => {
  const devicesList = [];
  const nodeIds = company.nodes || [];

  if (nodeIds.length === 0) return devicesList;

  // Crear una única consulta para todos los nodeIds
  const promises = nodeIds.map((nodeId) =>
    firebase.database().ref(`/multiApiData/SQL/${nodeId}/devices`).once('value')
  );

  const snapshots = await Promise.all(promises);

  snapshots.forEach((snapshot) => {
    const devices = snapshot.val();
    if (devices) {
      Object.keys(devices).forEach((key) => {
        devicesList.push(devices[key]);
      });
    }
  });

  return devicesList;
};

export const deleteServiceFromMeasurement = async (type, clientId, nodeId, deviceId, measurementDefinitions) => {
  const databaseRef = firebase
    .database()
    .ref(`/multiApiData/${type}/${clientId}/devices/${nodeId}/measurementDefinitions/${deviceId}`);

  try {
    // Elimina el servicio específico de la medición de la base de datos
    await databaseRef.remove();
    console.log(`Servicio eliminado de la medición exitosamente`);
  } catch (error) {
    console.error(`Error al eliminar el servicio de la medición:`, error);
    throw error;
  }
};

export const deleteMeasurementById = async (clientId, nodeId, deviceId, measurementId) => {
  const databaseRef = firebase
    .database()
    .ref(
      `/multiApiData/${clientId.type}/${clientId.id}/devices/${nodeId}/deviceMeasurements/${deviceId}/${measurementId}`
    );

  try {
    // Eliminar la medición específica de la base de datos
    await databaseRef.remove();
    console.log(`Medición con IDs ${measurementId} eliminada exitosamente.`);
  } catch (error) {
    console.error(`Error al eliminar la medición con ID ${measurementId}:`, error);
    throw error;
  }
};

export const deleteDevicesById = async (clientId) => {
  const databaseRef = firebase.database().ref(`/multiApiData/${clientId.type}/${clientId.id}`);

  try {
    // Eliminar la medición específica de la base de datos
    await databaseRef.remove();
    console.log(`Nodo con ID ${clientId.id} eliminado exitosamente.`);
  } catch (error) {
    console.error(`Error al eliminar nodo con ID ${clientId.id}:`, error);
    throw error;
  }
};

export const getDevicesmeasuresByCompany = async (company) => {
  const devicesList = [];

  const nodeIds = company.nodes || [];

  await Promise.all(
    nodeIds.map(async (nodeId) => {
      const snapshot = await firebase
        .database()
        .ref(`/multiApiData/KOLIBRI/${nodeId}/deviceMeasurements`)
        .once('value');

      const devices = snapshot.val();

      if (devices) {
        Object.keys(devices).forEach((key) => {
          const device = devices[key];
          devicesList.push(device);
        });
      }
    })
  );

  return devicesList;
};

export const getDevicesByCompanyFilterByZone = async (company, zoneId) => {
  const devicesList = [];

  const nodeIds = company.nodes || [];

  await Promise.all(
    nodeIds.map(async (nodeId) => {
      const snapshot = await firebase

        .database()

        .ref(`/multiApiData/KOLIBRI/${nodeId}/devices`)

        .once('value');

      const devices = snapshot.val();

      if (devices) {
        Object.keys(devices).forEach((key) => {
          const device = devices[key];

          // Verificamos si el dispositivo tiene la propiedad 'zones' y si alguno de los objetos en el arreglo tiene el 'id' igual a zoneId

          if (device.zones && device.zones.some((zone) => zone.id === zoneId.id)) {
            devicesList.push(device);
          }
        });
      }
    })
  );

  return devicesList;
};

export const getalldeviceById = async (nodoId, type) => {
  console.log('getalldeviceById called with:', { nodoId, type }); // Verifica los parámetros de entrada

  const List = [];
  const promise = firebase
    .database()
    .ref(`multiApiData/${type}/${nodoId}/devices`)
    .once('value')
    .then((snap) => {
      console.log('Snapshot received:', snap); // Verifica si se obtiene el snapshot
      const snapData = snap.val();
      console.log('Snapshot data:', snapData); // Muestra los datos obtenidos del snapshot

      if (snapData) {
        const nodo = {
          ...snapData,
        };
        console.log('Nodo object created:', nodo); // Verifica el objeto "nodo" generado

        Object.keys(nodo).forEach((item) => {
          console.log(`Processing item: ${item}, Value:`, nodo[item]); // Detalla cada item procesado
          List.push(nodo[item]);
        });
        console.log('Final List:', List); // Verifica el contenido de la lista "List"
      } else {
        console.warn('No data found for the given path');
      }

      return List;
    })
    .catch((error) => {
      console.error('Error fetching data from Firebase:', error); // Maneja y registra errores
      throw error;
    });

  return promise;
};

export const getalldeviceByIdsend = async (nodoId, type) => {
  console.log('getalldeviceByIdsend called with:', { nodoId, type });

  if (!nodoId || !type) {
    console.error('Invalid parameters: nodoId or type is missing');
    return []; // Retorna una lista vacía en caso de valores inválidos
  }

  const List = [];
  try {
    const snap = await firebase.database().ref(`multiApiData/${type}/${nodoId}/devices`).once('value');

    console.log('Snapshot received:', snap);
    const snapData = snap.val();
    console.log('Snapshot data:', snapData);

    if (snapData) {
      Object.keys(snapData).forEach((item) => {
        console.log(`Processing item: ${item}, Value:`, snapData[item]);
        List.push(snapData[item]);
      });
      console.log('Final List:', List);
    } else {
      console.warn('No data found for the given path');
    }
  } catch (error) {
    console.error('Error fetching data from Firebase:', error);
  }

  return List;
};

export const createMeasurementPath = async (client, deviceId) => {
  const measurementPathRef = firebase
    .database()
    .ref(`/multiApiData/${client.type}/${client.id}/deviceMeasurements/${deviceId}`);
  const measurementPathSnapshot = await measurementPathRef.once('value');
  const currentDate = new Date().toISOString();

  if (!measurementPathSnapshot.exists()) {
    await measurementPathRef.set({ updateDate: currentDate });
    console.log(
      `Se creó la ruta /multiApiData/${client.type}/${client.id}/deviceMeasurements/${deviceId} en Realtime Database.`
    );
  } else {
    const existingData = measurementPathSnapshot.val();
    await measurementPathRef.update({ ...existingData, updateDate: currentDate });
    console.log(
      `Se actualizó el campo updateDate en la ruta /multiApiData/${client.type}/${client.id}/deviceMeasurements/${deviceId} en Realtime Database.`
    );
  }
};

export function MultiApiDatagetDeviceById(id, type, nodoId) {
  console.log('roota', `multiApiData/${type}/${nodoId}/devices/${id}`);
  return firebase
    .database()
    .ref()
    .child(`multiApiData/${type}/${nodoId}/devices/${id}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export function getChannelListDevice(id, type, nodoId) {
  console.log('first12', id, type, nodoId);
  return firebase
    .database()
    .ref()

    .child(`/multiApiData/${type}/${nodoId}/devices/${id}/measurementDefinitions`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export function getChannelListDevices(ids, type, nodoId) {
  // Separar la cadena ids en números individuales
  const deviceIds = ids[0].split(',').map(Number); // Convertir los números de cadena a números enteros

  // Mapeamos cada deviceId a una promesa de consulta individual
  const promises = deviceIds.map((id) =>
    firebase
      .database()
      .ref(`/multiApiData/${type}/${nodoId}/devices/${id}/measurementDefinitions`)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log(`Data for device ${id}:`, snapshot.val());
          return { deviceId: id, channels: snapshot.val() }; // Retorna un objeto con el deviceId y sus canales
        }
      })
      .catch((error) => {
        console.error(`Error fetching data for device ${id}:`, error);
        throw error; // Lanza el error para manejarlo en el nivel superior
      })
  );

  return Promise.all(promises)
    .then((results) => {
      // Usamos un objeto para almacenar canales únicos basados en su id
      const uniqueChannelsMap = {};

      // Iteramos sobre los resultados y agregamos los canales únicos al objeto
      results.forEach((result) => {
        result.channels.forEach((channel) => {
          // Usamos el id del canal como clave en el objeto para garantizar la unicidad
          uniqueChannelsMap[channel.id] = channel;
        });
      });

      // Convertimos el objeto de canales únicos de vuelta a un array
      const allChannels = Object.values(uniqueChannelsMap);
      console.log('All channels:', allChannels);
      return allChannels; // Retorna todos los canales en un único array
    })
    .catch((error) => {
      console.error('Error fetching data for multiple devices:', error);
      throw error; // Lanza el error para manejarlo en el nivel superior
    });
}

export function MultiApiDatagetDeviceByIdmult(ids, type, nodoId) {
  const promises = ids.map((id) =>
    firebase
      .database()
      .ref()
      .child(`multiApiData/${type}/${nodoId}/devices/${id}`)
      .get()
      .then((snapshot) => (snapshot.exists() ? { id, deviceData: snapshot.val() } : { id, deviceData: [] }))
      .catch((error) => {
        console.error(error);
        return { id, deviceData: [] };
      })
  );

  return Promise.all(promises);
}

export function getChannelListDevicemult(ids, type, nodoId) {
  const promises = ids.map((id) =>
    firebase
      .database()
      .ref()
      .child(`/multiApiData/${type}/${nodoId}/devices/${id}/measurementDefinitions`)
      .get()
      .then((snapshot) => (snapshot.exists() ? { id, channels: snapshot.val() } : { id, channels: [] }))
      .catch((error) => {
        console.error(error);
        return { id, channels: [] };
      })
  );

  return Promise.all(promises);
}

export const getDevicesByZone = async (zoneId) => {
  const devicesList = [];

  try {
    const snapshot = await firebase.database().ref(`multiApiData/KOLIBRI/${zoneId}/devices`).once('value');

    const devices = snapshot.val();

    if (devices) {
      Object.keys(devices).forEach((key) => {
        const device = devices[key];
        devicesList.push(device);
      });
    }
  } catch (error) {
    console.error('Error fetching devices:', error);
  }

  return devicesList;
};

export const getDeviceCountById = async (nodoId, type) => {
  const snapshot = await firebase.database().ref(`multiApiData/${type}/${nodoId}/devices`).once('value');

  const devices = snapshot.val();
  const deviceCount = devices ? Object.keys(devices).length : 0;

  return deviceCount;
};

export const getDevicesMeasuremntesByDate = async (
  clientType,
  clientId,
  DeviceType,
  measurementDefinitionId,
  startDate,
  endDate
) => {
  const devicesList = [];

  try {
    const snapshot = await firebase
      .database()

      .ref(`/multiApiData/${clientType}/${clientId}/deviceMeasurements/${DeviceType}/${measurementDefinitionId}`)
      .orderByKey()
      .startAt(startDate.toISOString().substring(0, 10).replace(/-/g, '_')) // Formato AAAA-MM-DD
      .endAt(endDate.toISOString().substring(0, 10).replace(/-/g, '_')) // Formato AAAA-MM-DD
      .once('value');

    const devicesMeasuremnts = snapshot.val();

    if (devicesMeasuremnts) {
      // Itera a través de las fechas en el rango de fechas especificado
      // eslint-disable-next-line no-restricted-syntax
      for (const dateKey in devicesMeasuremnts) {
        if (Object.hasOwnProperty.call(devicesMeasuremnts, dateKey)) {
          const dateData = devicesMeasuremnts[dateKey];

          // Itera a través de las horas y minutos en cada fecha
          // eslint-disable-next-line no-restricted-syntax
          for (const hourMinuteKey in dateData) {
            if (Object.hasOwnProperty.call(dateData, hourMinuteKey)) {
              // Agrega los datos de cada hora y minuto a la lista resultante
              const hourMinuteData = dateData[hourMinuteKey];
              devicesList.push(...hourMinuteData);
            }
          }
        }
      }

      // Realiza alguna acción con la lista resultante, como mostrarla en la interfaz de usuario
    }
  } catch (error) {
    console.error('Error fetching devices:', error);
  }

  return devicesList;
};

export const getDevicesMeasuremntesByDatevistas = async (clientType, clientId, DeviceType, measurementDefinitionId) => {
  const cacheKey = `devicesMeasurements_${clientType}_${clientId}_${DeviceType}_${measurementDefinitionId}`;

  try {
    // Verificar si `localStorage` está disponible
    if (typeof localStorage !== 'undefined') {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        return JSON.parse(cachedData);
      }
    }

    // Consulta a Firebase para obtener las últimas mediciones
    const snapshot = await firebase
      .database()
      .ref(`/multiApiData/${clientType}/${clientId}/deviceMeasurements/${DeviceType}/${measurementDefinitionId}`)
      .orderByKey()
      .limitToLast(1)
      .once('value');

    const latestDevicesMeasurements = snapshot.val();
    const devicesList = [];

    if (latestDevicesMeasurements) {
      const latestDateKey = Object.keys(latestDevicesMeasurements)[0];
      const latestDateData = latestDevicesMeasurements[latestDateKey];

      // Iterar sobre las mediciones y agregarlas a la lista
      Object.values(latestDateData).forEach((hourMinuteData) => {
        devicesList.push(...hourMinuteData);
      });

      // Guardar los datos en `localStorage` si está disponible
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(cacheKey, JSON.stringify(devicesList));
      }
    }

    return devicesList;
  } catch (error) {
    console.error('Error fetching latest device measurements:', error);
    return []; // Devolver una lista vacía en caso de error
  }
};

export const getDevicesMeasuremntesByDatesqlzona = async (
  clientType,
  clientIds,
  DeviceType,
  measurementDefinitionId,
  startDate,
  endDate
) => {
  const devicesList = [];

  const deviceTypes = Array.isArray(DeviceType) ? DeviceType : [DeviceType];
  const measurementDefinitionIds = Array.isArray(measurementDefinitionId)
    ? measurementDefinitionId
    : [measurementDefinitionId];

  // Array para almacenar promesas
  const promises = [];

  try {
    // Itera sobre los elementos de clientIds
    clientIds.forEach((clientIdItem) => {
      deviceTypes.forEach((deviceTypeItem) => {
        measurementDefinitionIds.forEach((measurementDefinitionIdItem) => {
          const refPath = `/multiApiData/${clientType}/${clientIdItem}/${deviceTypeItem}`;

          const snapshotPromise = firebase
            .database()
            .ref(refPath)
            .orderByKey()
            .startAt(startDate.toISOString().substring(0, 10).replace(/-/g, '_'))
            .endAt(endDate.toISOString().substring(0, 10).replace(/-/g, '_'))
            .once('value');

          // Almacena la promesa en el array
          promises.push(snapshotPromise);
        });
      });
    });

    // Espera a que todas las promesas se resuelvan
    const snapshots = await Promise.all(promises);

    // Iteración sobre los resultados
    // Iteración sobre los resultados
    snapshots.forEach((snapshot, index) => {
      const devicesMeasuremnts = snapshot.val();

      console.log('Data for index', index, ':', devicesMeasuremnts);

      if (devicesMeasuremnts) {
        // Reemplaza el bucle for..in con Object.keys
        Object.keys(devicesMeasuremnts).forEach((dateKey) => {
          const dateData = devicesMeasuremnts[dateKey];

          if (dateData) {
            // Reemplaza el bucle for..in con Object.keys
            Object.keys(dateData).forEach((hourMinuteKey) => {
              const hourMinuteArray = dateData[hourMinuteKey];

              if (Array.isArray(hourMinuteArray) && hourMinuteArray.length > 0) {
                const hourMinuteData = hourMinuteArray[0];

                if (hourMinuteData) {
                  // Resto del código que usa hourMinuteData...

                  const formattedDate = hourMinuteData.DataTime; // Ajusta según la propiedad real en tu objeto

                  const hourMinuteDataFormatted = {
                    time: formattedDate,
                    value: hourMinuteData.value,
                    id: deviceTypes[index % deviceTypes.length],
                  };

                  devicesList.push({ ...hourMinuteDataFormatted });
                }
              }
            });
          }
        });
      }
    });

    // Realiza alguna acción con la lista resultante, como mostrarla en la interfaz de usuario
  } catch (error) {
    console.error('Error fetching latest values by hour:', error);
  }

  return devicesList;
};

export const getDevicesMeasuremntesByDatezona = async (
  clientType,
  clientId,
  DeviceType,
  measurementDefinitionId,
  startDate,
  endDate
) => {
  const devicesList = [];

  // Convierte clientId, DeviceType y measurementDefinitionId a arrays si no lo son
  const clientIds = Array.isArray(clientId) ? clientId : [clientId];
  const deviceTypes = Array.isArray(DeviceType) ? DeviceType : [DeviceType];
  const measurementDefinitionIds = Array.isArray(measurementDefinitionId)
    ? measurementDefinitionId
    : [measurementDefinitionId];

  // Array para almacenar promesas
  const promises = clientIds.flatMap((clientIdItem) =>
    deviceTypes.flatMap((deviceTypeItem) =>
      measurementDefinitionIds.map((measurementDefinitionIdItem) =>
        firebase
          .database()
          .ref(
            `/multiApiData/${clientType}/${clientIdItem}/deviceMeasurements/${deviceTypeItem}/${measurementDefinitionIdItem}`
          )
          .orderByKey()
          .startAt(startDate.toISOString().substring(0, 10).replace(/-/g, '_'))
          .endAt(endDate.toISOString().substring(0, 10).replace(/-/g, '_'))
          .once('value')
          .then((snapshot) => ({ deviceTypeItem, snapshot }))
      )
    )
  );

  try {
    // Espera a que todas las promesas se resuelvan
    const results = await Promise.all(promises);

    // Iteración sobre los resultados
    results.forEach(({ deviceTypeItem, snapshot }) => {
      const devicesMeasuremnts = snapshot.val();

      // Verifica si hay datos disponibles
      if (devicesMeasuremnts) {
        Object.keys(devicesMeasuremnts).forEach((dateKey) => {
          const dateData = devicesMeasuremnts[dateKey];
          const dayMeasurements = [];

          // Filtra y toma solo las primeras 24 mediciones por día
          Object.keys(dateData)
            .slice(0, 6)
            .forEach((hourMinuteKey) => {
              const hourMinuteData = {
                time: dateData[hourMinuteKey][0].time,
                timeUtc: dateData[hourMinuteKey][0].timeUtc,
                value: dateData[hourMinuteKey][0].value,
                id: deviceTypeItem,
              };
              dayMeasurements.push(hourMinuteData);
            });

          // Agrega las mediciones del día a la lista final
          devicesList.push(...dayMeasurements);
        });
      } else {
        console.log('No data available for Device Type:', deviceTypeItem);
      }
    });
  } catch (error) {
    console.error('Error fetching devices:', error);
  }

  return devicesList;
};

export const getDevicesMeasuremntesByDatesql = async (
  clientType,
  clientId,
  DeviceType,
  measurementDefinitionId,
  startDate,
  endDate
) => {
  const devicesList = [];
  const refPath = `/multiApiData/${clientType}/${clientId}/${DeviceType}`;

  try {
    const startKey = `${startDate.toISOString().substring(0, 10).replace(/-/g, '_')}_00:00`;
    const endKey = `${endDate.toISOString().substring(0, 10).replace(/-/g, '_')}_23:59`;

    const snapshot = await firebase.database().ref(refPath).orderByKey().startAt(startKey).endAt(endKey).once('value');

    const devicesMeasurements = snapshot.val();

    if (devicesMeasurements) {
      Object.entries(devicesMeasurements).forEach(([dateKey, dateData]) => {
        const parsedDate = new Date(dateKey.replace(/_/g, '-'));

        if (parsedDate >= startDate && parsedDate <= endDate) {
          Object.values(dateData).forEach((hourMinuteData) => {
            hourMinuteData.forEach((measurement) => {
              if (measurement.ChannelNumber === measurementDefinitionId) {
                devicesList.push({
                  ChannelNumber: measurement.ChannelNumber,
                  time: measurement.DataTime,
                  timeUtc: measurement.DataTime,
                  ID: measurement.ID,
                  value: measurement.value,
                  measurementDefinitionsId: measurement.ChannelNumber,
                });
              }
            });
          });
        }
      });
    }
  } catch (error) {
    console.error('Error fetching devices:', error);
  }

  return devicesList;
};

export const getDevicesMeasuremntesByDatesqlvistas = async (
  clientType,
  clientId,
  DeviceType,
  measurementDefinitionId,
  startDate,
  endDate
) => {
  const devicesList = [];

  try {
    const snapshot = await firebase
      .database()
      .ref(`/multiApiData/${clientType}/${clientId}/${DeviceType}`)
      .orderByKey()
      .limitToLast(1)
      .startAt(startDate.toISOString().substring(0, 10).replace(/-/g, '_'))
      .endAt(endDate.toISOString().substring(0, 10).replace(/-/g, '_'))
      .once('value');

    const devicesMeasurements = snapshot.val();

    if (devicesMeasurements) {
      // Itera a través de las fechas en el rango
      Object.keys(devicesMeasurements).forEach((dateKey) => {
        const dateData = devicesMeasurements[dateKey];

        // Itera a través de las horas en cada fecha
        Object.keys(dateData).forEach((hourKey) => {
          // Obtiene el último valor para cada hora
          const hourData = dateData[hourKey];
          const lastValue = hourData[hourData.length - 1];

          // Agrega el último valor a la lista resultante
          if (lastValue) {
            const modifiedMeasurement = {
              ChannelNumber: lastValue.ChannelNumber,
              time: lastValue.DataTime,
              timeUtc: lastValue.DataTime,
              ID: lastValue.ID,
              value: lastValue.value,
              measurementDefinitionsId: lastValue.ChannelNumber, // Cambia esto según tu lógica
            };

            devicesList.push(modifiedMeasurement);
          }
        });
      });

      // Realiza alguna acción con la lista resultante, como mostrarla en la interfaz de usuario
    }
  } catch (error) {
    console.error('Error fetching latest values by hour:', error);
  }

  return devicesList;
};

export const getDevicesMeasurementsType = async (
  clientType,
  clientId,
  DeviceType,
  measurementDefinitionId,
  startDate,
  endDate
) => {
  let listTotal = [];

  if (clientType === 'KOLIBRI') {
    listTotal = await getDevicesMeasuremntesByDate(
      clientType,
      clientId,
      DeviceType,
      measurementDefinitionId,
      startDate,
      endDate
    );
  } else if (clientType === 'SQL') {
    listTotal = await getDevicesMeasuremntesByDatesql(
      clientType,
      clientId,
      DeviceType,
      measurementDefinitionId,
      startDate,
      endDate
    );
    console.log('startDate1', startDate);
    console.log('endtDate1', endDate);
    console.log('clientType', clientType);
    console.log('clientId', clientId);
    console.log('DeviceType', DeviceType);
    console.log('measurementDefinitionId', measurementDefinitionId);
  }

  return listTotal;
};

export const getActiveDeviceCountById = async (nodoId, type) => {
  const snapshot = await firebase.database().ref(`multiApiData/${type}/${nodoId}/devices`).once('value');

  const devices = snapshot.val();
  let activeDeviceCount = 0;

  if (devices) {
    Object.keys(devices).forEach((key) => {
      const device = devices[key];
      if (device.status === 'activo') {
        // eslint-disable-next-line no-plusplus
        activeDeviceCount++;
      }
    });
  }

  return activeDeviceCount;
};

export const removeNode = async (type, nodoId) => {
  const rootRef = firebase.database().ref(`/multiApiData/KOLIBRI/-NntYiR33MqMmFvYnIsE`);
  try {
    await rootRef.remove();
    console.log(`Ruta "${nodoId}" eliminada exitosamente.`);
  } catch (error) {
    console.error(`Error al eliminar la ruta "${nodoId}":`, error);
  }
};
//  export const removeNode = async () => {
//    const data =  {
//     name: "CLIENTE GRUPO ROSUL",
//     type: "kolibri",
//     idApi:"-NcPDa7FZuF6XwMfEDzB",
//     requiredIds: [
//       {
//         device: 3492,
//         namedisposito: "dispo",
//         // requiredChannelIds: [2, 15],
//         requiredChannels: {
//           2: {
//             channel: 2,
//             needConversion: true,
//             profundidad: 1198,
//             convertTo: 10.22,
//             multiplo: 3.28084,
//           },
//           5: {
//             channel: 2,
//             needConversion: false,
//           },
//           15: {
//             channel: 15,
//             needConversion: true,
//             profundidad: 0,
//             convertTo: 10.22,
//             multiplo: 1,
//           },
//         },
//       },
//       {
//         device: 4084,
//         namedisposito: "disp3",
//         // requiredChannelIds: [11],
//         requiredChannels: {
//           5: {
//             channel: 2,
//             needConversion: false,
//           },
//           11: {
//             channel: 11,
//             needConversion: true,
//             profundidad: 0,
//             convertTo: 10.22,
//             multiplo: 1,
//           },
//         },
//       },
//       {
//         device: 7816,
//         namedisposito: "dispo2",
//         // requiredChannelIds: [11],
//         requiredChannels: {
//           11: {
//             channel: 11,
//             needConversion: true,
//             profundidad: 0,
//             convertTo: 10.22,
//             multiplo: 1,
//           },
//         },
//       },
//       {
//         device: 4008,
//         namedisposito: "dispo5",
//         // requiredChannelIds: [11],
//         requiredChannels: {
//           5: {
//             channel: 2,
//             needConversion: false,
//           },
//           11: {
//             channel: 11,
//             needConversion: true,
//             profundidad: 0,
//             convertTo: 10.22,
//             multiplo: 1,
//           },
//         },
//       },
//     ],
//     extension: "CSV",
//     sendType: "FTP",
//     url: "168.234.51.168",
//     port: 7018,
//     user: "pozos",
//     password: "R0ssul123*",
//     // hora y gpt
//     hour: 0, // 12:00 media noche
//     utc: -6,
//     eachXHours: 1,
//     dataCollectionTime: 6,
//   }
//    const rootRef = firebase.database().ref(`/multiApiClients/-NYZL_WJ3q8Mue3AQU-o`);
//    const newEntryRef = rootRef.push(); // Genera un nuevo ID único para la entrada
//    try {
//      await newEntryRef.set(data);
//      console.log("Datos insertados exitosamente con un nuevo ID único:", newEntryRef.key);
//    } catch (error) {
//      console.error("Error al insertar los datos:", error);
//    }
//  };

export const insertDataWithUniqueId = async () => {
  const data = {
    name: 'CLIENTE GRUPO ROSUL',
    type: 'kolibri',
    requiredIds: [
      {
        device: 3492,
        namedisposito: 'dispo',
        requiredChannels: {
          2: {
            channel: 2,
            needConversion: true,
            profundidad: 1198,
            convertTo: 10.22,
            multiplo: 3.28084,
          },
          5: {
            channel: 2,
            needConversion: false,
          },
          15: {
            channel: 15,
            needConversion: true,
            profundidad: 0,
            convertTo: 10.22,
            multiplo: 1,
          },
        },
      },
      // ... (other requiredIds entries)
    ],
    extension: 'EXTENSION_TYPE.csv',
    sendType: 'SEND_TYPE.ftp',
    url: '168.234.51.168',
    port: 7018,
    user: 'pozos',
    password: 'R0ssul123*',
    hour: 0,
    utc: -6,
    eachXHours: 1,
    dataCollectionTime: 6,
  };

  const rootRef = firebase.database().ref(`/multiApiClients`);
  const newEntryRef = rootRef.push(); // Genera un nuevo ID único para la entrada

  try {
    await newEntryRef.set(data);
  } catch (error) {
    console.error('Error al insertar los datos:', error);
  }
};

export function MultiApiDataUpdateDeviceById(id, type, nodoId, updatedData) {
  return firebase
    .database()
    .ref(`multiApiData/${type}/${nodoId}/devices/${id}`)
    .update(updatedData)
    .then(() => {})
    .catch((error) => {
      console.error(`Error al actualizar el dispositivo con ID ${id}:`, error);
      throw error;
    });
}

export function MultiApiDataUpdateDeviceByIdsend(id, companyId, newData) {
  const requiredIdsPath = `channel/${companyId}/${id}/requiredIds`;
  console.log('data12', requiredIdsPath);
  console.log('newDatanewData', newData);

  return firebase
    .database()
    .ref(requiredIdsPath)
    .once('value')
    .then((snapshot) => {
      // Obtener los datos existentes y asegurarse de que sean un array
      const existingData = Array.isArray(snapshot?.val()) ? snapshot.val() : [];
      console.log('firstexistingData', existingData);

      // Asegurarse de que newData sea un array
      const formattedNewData = Array.isArray(newData) ? newData : [newData];
      console.log('formattedNewData:', formattedNewData);

      // Formatear los nuevos datos para que cada elemento sea directamente el contenido de "0", no anidado en requiredChannels
      const updatedChannels = [
        ...existingData,
        ...formattedNewData.map((data) => ({
          // Aquí colocamos el contenido de data directamente como entrada del array
          ...data,
        })),
      ];

      console.log('🟢 Datos combinados (formateados):', JSON.stringify(updatedChannels, null, 2));

      // Sobrescribir los datos en requiredIds con la nueva estructura
      return firebase.database().ref(requiredIdsPath).set(updatedChannels);
    })
    .catch((error) => {
      console.error(`Error al actualizar los datos en ${requiredIdsPath} `, error);
      throw error;
    });
}

export function MultiApiDataUpdateDeviceByIdsendstatus(companyKey, deviceKey, listIndex, newData) {
  return firebase
    .database()
    .ref(`channel/${companyKey}/${deviceKey}/requiredIds/${listIndex}/status`)
    .set(newData.status)
    .then(() => {
      console.log('Status actualizado exitosamente');
    })
    .catch((error) => {
      console.error(`Error al actualizar el status:`, error);
      throw error;
    });
}

export function MultiApiDataUpdateDeviceByIdsendchannel(companyId, idchanel, updatedData) {
  console.log('rota', companyId, idchanel, updatedData);
  return firebase
    .database()
    .ref(`channel//${companyId}/requiredIds/requiredChannels/${idchanel}`)
    .update(updatedData)
    .then(() => {})
    .catch((error) => {
      console.error(`Error al actualizar el dispositivo con ID $:`, error);
      throw error;
    });
}

export function MultiApiDataConnectionById(id, type, nodoId, updatedData) {
  return firebase
    .database()
    .ref(`multiApiData/${type}/${nodoId}/devices/${id}/conexion`)
    .set(updatedData.conexion)
    .then(() => {
      console.log(`Propiedad 'conexion' del dispositivo con ID ${id} actualizada correctamente.`);
    })
    .catch((error) => {
      console.error(`Error al actualizar la propiedad 'conexion' del dispositivo con ID ${id}:`, error);
      throw error;
    });
}

export function MultiApiDataUpdateDeviceById2(id, updatedData, type, idNodo) {
  return firebase
    .database()
    .ref(`multiApiData/${type}/${idNodo}/devices/${id}`)
    .update(updatedData)
    .then(() => {
      console.log(`Dispositivo con ID ${id} ${idNodo} actualizado correctamente.`);
    })
    .catch((error) => {
      console.error(`Error al actualizar el dispositivo con ID ${id}:`, error);
      throw error;
    });
}

export function MultiApiDataUpdateDeviceByIdsql(name, type, nodoId, updatedData) {
  return firebase
    .database()
    .ref(`multiApiData/${type}/${nodoId}/devices/${name}`)
    .update(updatedData)
    .then(() => {
      console.log(`Dispositivo con ID ${name} actualizado correctamente.`);
    })
    .catch((error) => {
      console.error(`Error al actualizar el dispositivo con ID ${name}:`, error);
      throw error;
    });
}

export function MultiApiDataUpdatemeasuresById(id, type, nodoId, updatedData) {
  return firebase
    .database()
    .ref(`/multiApiData/${type}/${nodoId}/devices/${id}/measurementDefinitions`)
    .update(updatedData);
}

export function MultiApiDataUpdatemeasuresByIdmult(ids, type, nodoId, updatedData) {
  // Asegurarse de que ids sea un array separado por comas
  const deviceIds = Array.isArray(ids) ? ids : ids.split(',').map((id) => id.trim());

  // Mapeamos cada id a una promesa de actualización
  const promises = deviceIds.map((id) =>
    // Convertimos el id en un string para asegurar que sea un valor válido para la ruta de Firebase
    firebase
      .database()
      .ref(`/multiApiData/${type}/${nodoId}/devices/${String(id)}/measurementDefinitions`)
      .update(updatedData)
      .then(() => {
        return id; // Devolvemos el id actualizado para referencia futura si es necesario
      })
      .catch((error) => {
        console.error(`Error updating measurement definitions for device ${id}:`, error);
        throw error; // Lanza el error para manejarlo en el nivel superior
      })
  );

  return Promise.all(promises)
    .then((updatedIds) => {
      console.log('All measurement definitions updated successfully');
      return updatedIds; // Devolvemos los IDs actualizados si es necesario
    })
    .catch((error) => {
      console.error('Error updating measurement definitions for multiple devices:', error);
      throw error; // Lanza el error para manejarlo en el nivel superior
    });
}

export function MultiApiDataUpdatemeasuresByIdcanales(id, type, nodoId, updatedData) {
  return firebase.database().ref(`/multiApiData/${type}/${nodoId}/devices/${id}`).update(updatedData);
}

export function MultiApiDataUpdatemeasuresById2(id, type, nodoId, newValue) {
  return firebase.database().ref(`/meses`).update(newValue);
}

export function MultiApiDataUpdatemeasuresById3(id, type, nodoId, updatedData) {
  return firebase.database().ref(`/multiApiData/${type}/${nodoId}/devices/${id}`).update(updatedData);
}

export function MultiApiDataUpdatemeasuresById3mult(ids, type, nodoId, updatedData) {
  // Asegurarse de que ids sea un array separado por comas
  const deviceIds = Array.isArray(ids) ? ids : ids.split(',').map((id) => id.trim());

  // Mapeamos cada id a una promesa de actualización
  const promises = deviceIds.map((id) =>
    // Convertimos el id en un string para asegurar que sea un valor válido para la ruta de Firebase
    firebase
      .database()
      .ref(`/multiApiData/${type}/${nodoId}/devices/${String(id)}`)
      .update(updatedData)
      .then(() => {
        console.log(`Data updated for device ${id}`);
        return id; // Devolvemos el id actualizado para referencia futura si es necesario
      })
      .catch((error) => {
        console.error(`Error updating data for device ${id}:`, error);
        throw error; // Lanza el error para manejarlo en el nivel superior
      })
  );

  return Promise.all(promises)
    .then((updatedIds) => {
      console.log('All devices updated successfully');
      return updatedIds; // Devolvemos los IDs actualizados si es necesario
    })
    .catch((error) => {
      console.error('Error updating data for multiple devices:', error);
      throw error; // Lanza el error para manejarlo en el nivel superior
    });
}

export function obtenerUltimaFechaPorMes(idnodo, mes) {
  console.log('idnodos', idnodo);

  return firebase
    .database()
    .ref(`/historico/${idnodo}/${mes}`)
    .orderByKey()
    .limitToLast(1)
    .once('value')

    .then((snapshot) => {
      const ultimaFecha = snapshot.val();
      // Obtenemos el valor del objeto que contiene la última fecha
      const fechaKey = Object.keys(ultimaFecha)[0];
      return ultimaFecha[fechaKey];
    });
}

export function MultiApiDataUpdatemonth(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/diciembre/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthene(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/enero/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthfeb(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/febrero/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthmar(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/marzo/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthabr(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/abril/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthmay(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/mayo/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthjun(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/junio/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthjul(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/julio/${formattedFecha}`).update(newValue);
}
export function MultiApiDataUpdatemonthago(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/agosto/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthsep(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/septiembre/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthoct(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/octubre/${formattedFecha}`).update(newValue);
}

export function MultiApiDataUpdatemonthnov(nodoId, fecha, newValue) {
  // Reemplaza caracteres no permitidos en la fecha
  const formattedFecha = fecha.replace(/[.:#$[\]]/g, '_');

  return firebase.database().ref(`/historico/${nodoId}/noviembre/${formattedFecha}`).update(newValue);
}

export const getMeasurementsById = async (type, nodoId, id) => {
  const measurementsList = [];

  const measurementPath = `/multiApiData/KOLIBRI/${nodoId}/devices/${id}/measurementDefinitions`;

  try {
    const snapshot = await firebase.database().ref(measurementPath).once('value');
    const measurements = snapshot.val();

    if (measurements) {
      Object.keys(measurements).forEach((key) => {
        const measurement = measurements[key];
        if (measurement.service) {
          measurementsList.push(measurement);
        }
      });
    }
  } catch (error) {
    console.error('Error fetching measurements:', error);
  }

  return measurementsList;
};

export const getMeasurementsByIdsql = async (type, nodoId, id) => {
  const measurementsList = [];

  const measurementPath = `/multiApiData/SQL/${nodoId}/devices/${id}/measurementDefinitions`;

  try {
    const snapshot = await firebase.database().ref(measurementPath).once('value');
    const measurements = snapshot.val();

    if (measurements) {
      Object.keys(measurements).forEach((key) => {
        const measurement = measurements[key];
        if (measurement.service) {
          measurementsList.push(measurement);
        }
      });
    }
  } catch (error) {
    console.error('Error fetching measurements:', error);
  }

  return measurementsList;
};

export const banishServer = async (server, companyId) => {
  const rootRef = firebase.database().ref('/');
  const articlesBanished = rootRef.child(`banished/servers/${companyId}`);

  await articlesBanished.push(server);
};

// export const softDeleteNodo = async (id, companyId) => {
//   const articleRemoved = await getalldeviceById(id, companyId);
//   articleRemoved.status = 'removed';
//   await banishServer(articleRemoved, companyId);

//   const rootRef = firebase.database().ref('/');

//   const articles = rootRef.child(`nodes/${companyId}`);
//   const currentArticleRef = articles.child(id);
//   currentArticleRef.remove();

//   const companies = rootRef.child('companies');
//   const parentCompanyRef = companies.child(companyId);
//   const parentCompany = await getCompanyById(companyId);
//   const serversArray = parentCompany?.nodes;

//   const companyServers = serversArray.filter((item) => item !== id);

//   const updatedParentCompany = {
//     ...parentCompany,
//     nodes: companyServers,
//   };

//   const update = await parentCompanyRef.update(updatedParentCompany);
//   return update;
// };
