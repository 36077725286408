import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { getCompanyById } from './companies';
import { createLog } from '../redux/slices/log';
import { deleteDevicesById } from './multiapiData';

const database = firebase.database();
export const ref = database.ref('nodes');

export const listenForChangesofRealTimeDataSql = async (companyID, id, callback) => {
  console.log('data escucha', id, companyID);
  const myTable = database.ref(`nodes/${companyID}/${id}/realtimeData`);
  myTable.on('value', async (snapshot) => {
    callback(snapshot.val());
  });
};

export const createNewNodo = async (nodo, companyId, branchId) => {
  try {
    const rootRef = firebase.database().ref('/');
    const nodes = rootRef.child(`nodes/${companyId}`);
    const companies = rootRef.child('companies');
    const parentCompanyRef = companies.child(companyId);
    const parentCompany = await getCompanyById(companyId);

    const newNodo = await nodes.push(nodo);
    const getNodoCreated = await getNodoById(newNodo.key, companyId);
    const findNodoCreated = nodes.child(`${newNodo.key}`);
    await findNodoCreated.set(getNodoCreated);

    const log = await createLog('nodo', 'create', newNodo.key, [], nodo);

    nodo = {
      ...nodo,
      log: { ...nodo.log, [log]: true },
    };

    newNodo.update(nodo);

    const companynodes = parentCompany.nodes?.filter((item) => item) || [];
    const updatedParentCompany = {
      ...parentCompany,
      nodes: [...companynodes, newNodo.key],
    };

    const update = await parentCompanyRef.update(updatedParentCompany);
    return update;
  } catch (error) {
    console.log(error);
  }
};

export const getNodoByCompany = async (company) => {
  const articlesList = [];
  console.log('company', company);
  const listArticlesId = company.nodes || [];

  await Promise.all(
    listArticlesId.map(async (id) => {
      await firebase
        .database()
        .ref(`nodes/${company.id}/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const article = {
            ...snapData,
            id: snap.key,
          };
          articlesList.push(article);
        });
    })
  );
  return articlesList;
};

export const getNodoByCompanyall = async (companyId) => {
  const articlesList = [];

  console.log('Iniciando fetch de nodos para la compañía con ID:', companyId);

  try {
    // Referencia a los nodos bajo el ID de la compañía
    const nodesRef = firebase.database().ref(`nodes/${companyId}`);
    console.log('Referencia a Firebase creada:', `nodes/${companyId}`);

    // Obtener los nodos asociados a la compañía
    const snapshot = await nodesRef.once('value');
    console.log('Snapshot obtenido:', snapshot.exists() ? snapshot.val() : 'No hay datos');

    const nodesData = snapshot.val();

    if (nodesData) {
      console.log('Datos de nodos encontrados:', nodesData);

      // Convertir los nodos en un array y agregarles el ID
      Object.keys(nodesData).forEach((key) => {
        const nodo = {
          ...nodesData[key],
          id: key,
        };
        articlesList.push(nodo);
        console.log('Nodo procesado:', nodo);
      });
    } else {
      console.log('No se encontraron nodos para la compañía con ID:', companyId);
    }
  } catch (error) {
    console.error('Error al obtener los nodos de Firebase:', error);
  }

  console.log('Lista final de nodos:', articlesList);
  return articlesList;
};

export function getArticleByCompany(articleId, companyId) {
  return firebase
    .database()
    .ref()
    .child(`articles/${companyId}/${articleId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : []))
    .catch((error) => {
      console.error(error);
    });
}

export const getNodoById = async (nodoId, companyId) => {
  const promise = firebase
    .database()
    .ref(`nodes/${companyId}/${nodoId}`)
    .once('value')
    .then((snap) => {
      const snapData = snap.val();
      const nodo = {
        ...snapData,
        id: snap.key,
      };
      return nodo;
    });

  return promise;
};

export const banishServer = async (server, companyId) => {
  const rootRef = firebase.database().ref('/');
  const articlesBanished = rootRef.child(`banished/servers/${companyId}`);

  await articlesBanished.push(server);
};

export const softDeleteNodo = async (id, companyId) => {
  const rootRef = firebase.database().ref('/');

  // Referencia al nodo específico
  const articles = rootRef.child(`nodes/${companyId}`);
  const currentArticleRef = articles.child(id);

  // Actualizar la propiedad 'status' a false en lugar de eliminar
  await currentArticleRef.update({ active: false });

  // Obtener la compañía y sus nodos actuales
  const companies = rootRef.child('companies');
  const parentCompanyRef = companies.child(companyId);
  const parentCompany = await getCompanyById(companyId);
  const serversArray = parentCompany?.nodes;

  // Mantener el nodo en la lista de nodos de la compañía
  const updatedParentCompany = {
    ...parentCompany,
    nodes: serversArray, // No se elimina el nodo de la lista
  };

  // Actualizar la compañía con la lista sin cambios
  const update = await parentCompanyRef.update(updatedParentCompany);
  return update;
};

export const updateNodo = async (nodo, companyId) => {
  const currentNodo = await getNodoById(nodo.id, companyId);
  console.log('request ', nodo, companyId);
  console.log('currentNodo', currentNodo);
  const log = await createLog('nodo', 'edit', nodo.id, currentNodo, nodo);
  console.log('nodo1', currentNodo.requiredTable);
  console.log('nodo2', nodo.requiredTable);
  console.log('nodox', nodo);
  console.log('nodox2', companyId);
  // Fusiona los valores antiguos y nuevos de requiredtable
  const newNodo = {
    ...nodo,
    ...currentNodo,
    log: { ...nodo.log, [log]: true },
    requiredTable: {
      ...currentNodo.requiredTable,
      ...nodo.requiredTable,
    },
  };

  console.log('nodo3', newNodo);

  const updates = {};
  updates[`nodes/${companyId}/${nodo.id}`] = newNodo;

  firebase.database().ref().update(updates);
};
